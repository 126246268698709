import Vue from 'vue'
import VueRouter from 'vue-router'
/* eslint-disable */
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect: 'login',
  // },
  //home
  {
    path: '/home',
    name: 'home',
    component: () =>
      import('@/views/Home.vue'),
    // beforeEnter(to, from, next) {
    //   if (localStorage.getItem('accessToken') && account_type === 'manager') {
    //     next()
    //   } else {
    //     next('/login')
    //   }
    // },

    meta: {
      layout: 'content',
    },
  },
  //courses
  {
    path: '/courses',
    name: 'courses',
    component: () =>
      import('@/views/manager/courses/courses.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/course/:id/:title',
    name: 'course',
    component: () =>
      import('@/views/manager/courses/course.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/courses/addCourse',
    name: 'addCourse',
    component: () =>
      import('@/views/manager/courses/addCourse.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  //teachers
  {
    path: '/teachers',
    name: 'teachers',
    component: () =>
      import('@/views/manager/teachers/teachers.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/teachers/addTeacher',
    name: 'addTeacher',

    component: () =>
      import('@/views/manager/teachers/addTeacher.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/teachers/:id/:name',
    name: 'TeacherProfile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('@/views/manager/teachers/TeacherProfile'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/teachers/monthlyPaymentDetails/:id/:name/:startDate/:endDate',
    name: 'monthlyPaymentDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "MonthlyPaymentDetails" */
        '@/views/manager/teachers/teacherProfileInfo/monthlyPayment/monthlyPaymentDetails.vue'
      ),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  // End teachers

  // students
  //stopedStudent
  {
    path: '/stopedStudent',
    name: 'stopedStudent',
    component: () =>
      import('@/views/manager/stopedStudent/stopedStudent.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //stopedStudent
  {
    path: '/printStudents',
    name: 'printStudents',
    component: () =>
      import('@/views/manager/students/print/invoice.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'blank',
    },
  },

  //newStudent
  {
    path: '/newStudent',
    name: 'newStudent',
    component: () =>
      import('@/views/manager/newStudent/newStudent.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //deletedStudent
  {
    path: '/deletedStudent',
    name: 'deletedStudent',
    component: () =>
      import('@/views/manager/deletedStudent/deletedStudent.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //AllStudent
  {
    path: '/allStudent',
    name: 'allStudent',
    component: () =>
      import('@/views/manager/students/allStudents.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  // students

  // Absence Show
  {
    path: '/showAbsence',
    name: 'absenceShow',
    component: () =>
      import('@/views/manager/absence/absenceShow/absenceSelectClass.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/showAbsence/show/:class_school_id/:class_school_name',
    name: 'showingAbsence',
    component: () =>
      import('@/views/manager/absence/absenceShow/absence.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/showAbsence/show/details/:account_id/:account_name',
    name: 'showingAbsenceDetails',
    component: () =>
      import('@/views/manager/absence/absenceShow/absenceDetails.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  // Absence Show

  // absence Add
  {
    path: '/absence/add',
    name: 'absenceAdd',

    component: () =>
      import('@/views/manager/absence/absenceAdd/selectClassAndDate.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/absence/add/:class_school_id/:date/:class_school_name',
    name: 'absenceAddShow',
    component: () =>
      import('@/views/manager/absence/absenceAdd/absence.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/absence/addNew/:class_school_id/:date/:class_school_name',
    name: 'absenceAddShowTest',
    component: () =>
      import('@/views/manager/absence/absenceAdd/absenceNew.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  // absence Add

  // Absence Show Teacher
  {
    path: '/showAbsenceTeacher',
    name: 'absenceShowTeacher',
    component: () =>
      import('@/views/manager/absenceTeacher/absenceShow/absence.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/showAbsenceTeacher/show/details/:account_id/:account_name',
    name: 'showingAbsenceDetailsTeacher',
    component: () =>
      import('@/views/manager/absenceTeacher/absenceShow/absenceDetails.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  // Absence Show Teacher

  // absence Add Teacher
  {
    path: '/absenceTeacher/add',
    name: 'absenceAddTeacher',

    component: () =>
      import('@/views/manager/absenceTeacher/absenceAdd/selectClassAndDate.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/absenceTeacher/add/date/:date',
    name: 'absenceAddShowTeacher',
    component: () =>
      import('@/views/manager/absenceTeacher/absenceAdd/absence.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  // absence Add Teacher

  // lecture Add Teacher
  {
    path: '/lectureTeacher/add',
    name: 'lectureAddTeacher',

    component: () =>
      import('@/views/manager/absenceTeacher/lectureAdd/selectClassAndDate.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/lectureTeacher/add/date/:date/time/:time/class_school/:class_school/class_school_id/:class_school_id/class_study/:class_study',
    name: 'lectureAddShowTeacher',
    component: () =>
      import('@/views/manager/absenceTeacher/lectureAdd/lecture.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  // absence Add Teacher

  // review Show
  {
    path: '/showReview',
    name: 'showReview',
    component: () =>
      import('@/views/manager/review/reviewShow/studentSelect.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/showReview/show/details/:account_id/:account_name',
    name: 'showingReviewDetailsTeacher',
    component: () =>
      import('@/views/manager/review/reviewShow/review.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  // review Show

  // review Add
  {
    path: '/review/add',
    name: 'reviewAdd',

    component: () =>
      import('@/views/manager/review/reviewAdd/selectClassAndDate.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/review/add/:class_school_id/:date/:class_school_name',
    name: 'reviewAddShow',
    component: () =>
      import('@/views/manager/review/reviewAdd/review.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  // review Add

  // dailyExam Show
  {
    path: '/showDailyExam',
    name: 'showDailyExam',
    component: () =>
      import('@/views/manager/dailyExams/show/degreeShow.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  // {
  //   path: '/dailyExamAdd/class_id/:class_id/date/:date/class_name/:class_name',
  //   name: 'showDailyExamShow',
  //   component: () =>
  //     import ('@/views/dailyExams/show/degreeShow.vue'),
  //   beforeEnter(to, from, next) {
  //     if (
  //       localStorage.getItem('accessToken') &&
  //       JSON.parse(localStorage.getItem('results')).account_type === 'manager'
  //     ) {
  //       next()
  //     } else {
  //       next('/')
  //     }
  //   },
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  // dailyExam Show

  // dailyExam Add
  {
    path: '/dailyExamAdd',
    name: 'dailyExamAdd',

    component: () =>
      import('@/views/manager/dailyExams/add/selectClassSchoolAndSubjectAndDate.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dailyExamAdd/class_id/:class_id/subject/:subject/date/:date/class_name/:class_name/maxDegree/:maxDegree/exams_id/:exams_id',
    name: 'dailyExamAddShow',
    component: () =>
      import('@/views/manager/dailyExams/add/degree.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  // dailyExam Add

  //schedule
  {
    path: '/schedule',
    name: 'schedule',
    component: () =>
      import('@/views/manager/schedule/selectClassForSchedule.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/schedule/:class_school_id/:class_school_leader/:classes_name',
    name: 'scheduleEdit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "selectClassForSchedule" */ '@/views/manager/schedule/schedule.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  //schedule


  // subject
  {
    path: '/subject',
    name: 'subject',
    component: () =>
      import('@/views/manager/subject/subject.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //classSchool
  {
    path: '/classSchool',
    name: 'classSchool',
    component: () =>
      import('@/views/manager/classSchool/classSchool.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/classSchoolInvoice/id/:id',
    name: 'classSchoolInvoice',
    component: () =>
      import('@/views/manager/classSchool/classSchoolInvoice.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //classSchool

  //exams
  {
    path: '/examsShow',
    name: 'examsShow',
    component: () =>
      import('@/views/manager/exams/selectClassSchoolExam.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/examsMultiAdd',
    name: 'examsMultiAdd',
    component: () =>
      import('@/views/manager/exams/examsMultiAdd/examsAdd.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/exams/exam/:class_school_id/:class_school_leader/:classes_name',
    name: 'examsProfile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "exams" */ '@/views/manager/exams/exams.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/exams/examSubject/:class_school/:id/:name',
    name: 'examSubject',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "examSubject" */ '@/views/manager/exams/examSubject.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  // {
  //     path: '/exams/studentDegree/:class_school/:id/:name',
  //     name: 'studentDegree',
  //     // route level code-splitting
  //     // this generates a separate chunk (about.[hash].js) for this route
  //     // which is lazy-loaded when the route is visited.
  //     component: () =>
  //         import ( /* webpackChunkName: "examSubject" */ '@/views/manager/exams/studentDegree.vue'),
  //     beforeEnter(to, from, next) {
  //         if (
  //             localStorage.getItem('accessToken') &&
  //             JSON.parse(localStorage.getItem('results')).account_type === 'manager'
  //         ) {
  //             next()
  //         } else {
  //             next('/')
  //         }
  //     },
  //     meta: {
  //         layout: 'content',
  //     },
  // },

  //exams

  // degrees
  {
    path: '/degrees/add',
    name: 'degreesAdd',
    component: () =>
      import( /* webpackChunkName: "examSubject" */ '@/views/manager/degrees/degreesMultiAdd/add.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/degrees/add/degrees/class_school/:class_school/subject_id/:subject_id/degree_max/:degree_max/degree_exam_name/:degree_exam_name/degree_exam_id/:degree_exam_id',
    name: 'degreesAddDegrees',
    component: () =>
      import( /* webpackChunkName: "examSubject" */ '@/views/manager/degrees/degreesAddDegrees.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/degrees/show',
    name: 'degreesShow',
    component: () =>
      import( /* webpackChunkName: "examSubject" */ '@/views/manager/degrees/degreeShow.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //notification
  {
    path: '/notification',
    name: 'notification',
    component: () =>
      import('@/views/manager/notification/notification.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/notification/addNotification',
    name: 'addNotification',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "addNotification" */ '@/views/manager/notification/addNotification.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/notificationForStudent/:class_school/:student_id/:student_name',
    name: 'notificationForStudent',
    component: () =>
      import('@/views/manager/notification/notificationForStudent.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/notificationForTeacher/:teacher_id/:teacher_name',
    name: 'notificationForTeachert',
    component: () =>
      import('@/views/manager/notification/notificationForTeacher.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  //notification

  //students
  {
    path: '/students',
    name: 'students',

    component: () =>
      import('@/views/manager/students/selectClass.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/students/studentInClass/:class_school_id/:class_school_leader/:classes_name',
    name: 'studentInClass',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "students" */ '@/views/manager/students/students.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  //addStudent
  {
    path: '/students/addStudent/:class_school_id',
    name: 'addStudent',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "selectClassSchool" */ '@/views/manager/students/addStudent.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  //studentProfile
  {
    path: '/students/studentProfile/:id/:name',
    name: 'studentProfile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "selectClassSchool" */ '@/views/manager/students/studentProfile.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  //examForStudent
  {
    path: '/examForStudent/studentId/:studentId/studentName/:studentName/classSchoolId/:classSchoolId',
    name: 'examForStudent',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "selectClassSchool" */ '@/views/manager/students/studentExams.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  //student bills
  {
    path: '/studentBills/studentId/:studentId/studentName/:studentName',
    name: 'studentBills',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "selectClassSchool" */ '@/views/manager/students/student_account/student_bills.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  //student bills
  {
    path: '/studentBills/showBills/student_id/:student_id/student_name/:student_name/showDetails/id/:id/name/:name',
    name: 'studentPayments',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "selectClassSchool" */ '@/views/manager/students/student_account/student_payment.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  // studentBillDetailsInvoice
  {
    path: '/studentBillDetailsInvoice',
    name: 'studentBillDetailsInvoice',
    component: () =>
      import('@/views/manager/students/student_account/invoice.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'blank',
    },
  },
  //salaryPrint
  // {
  //   path: '/salaryPrint',
  //   name: 'printStudentSalary',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import ( /* webpackChunkName: "selectClassSchool" */ '@/views/students/studentProfileInfo/printStudentSalary'),
  //   beforeEnter(to, from, next) {
  //     if (
  //       localStorage.getItem('accessToken') &&
  //       JSON.parse(localStorage.getItem('results')).account_type === 'manager'
  //     ) {
  //       next()
  //     } else {
  //       next('/')
  //     }
  //   },
  //   meta: {
  //     layout: 'blank',
  //   },
  // },
  //studentSalaryDetails
  // {
  //   path: '/students/studentProfile/studentSalaryDetails/:id/:name',
  //   name: 'studentSalaryDetails',
  //   component: () =>
  //     import ('@/views/students/studentProfileInfo/studentSalaryDetails/studentSalaryDetails.vue'),
  //   beforeEnter(to, from, next) {
  //     if (
  //       localStorage.getItem('accessToken') &&
  //       JSON.parse(localStorage.getItem('results')).account_type === 'manager'
  //     ) {
  //       next()
  //     } else {
  //       next('/')
  //     }
  //   },
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  //students

  //drivers
  {
    path: '/drivers',
    name: 'drivers',
    component: () =>
      import('@/views/manager/driver/driver.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/drivers/addDriver',
    name: 'addDriver',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "drivers" */ '@/views/manager/driver/addDriver.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/drivers/:id/:name',
    name: 'driversProfile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "drivers" */ '@/views/manager/driver/driverProfile.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  // employee
  {
    path: '/employees',
    name: 'employees',
    component: () =>
      import('@/views/manager/employees/employees.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/employees/addEmployees',
    name: 'addEmployees',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "drivers" */ '@/views/manager/employees/addEmployee.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/employees/:id/:name',
    name: 'employeesProfile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "drivers" */ '@/views/manager/employees/employeeProfile.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },


  {
    path: '/stream',
    name: 'stream',
    component: () =>
      import('@/views/manager/steaming/stream'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  //drivers

  //requistVacation
  {
    path: '/requistVacation',
    name: 'requistVacation',
    component: () =>
      import('@/views/manager/aboutSchool/requistVacation.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  //requistVacation

  //certificate
  {
    path: '/certificate',
    name: 'certificate',
    component: () =>
      import('@/views/manager/aboutSchool/certificate.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  //certificate

  //counting
  {
    path: '/counting',
    name: 'counting',
    component: () =>
      import('@/views/manager/counting.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        JSON.parse(localStorage.getItem('results')).account_type === 'manager'
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  //hireReq

  //latestNews
  {
    path: '/latestNews',
    name: 'latestNews',
    component: () =>
      import('@/views/manager/aboutSchool/latestNews/latestNews.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/latestNews/addLatestNews',
    name: 'addLatestNews',
    component: () =>
      import('@/views/manager/aboutSchool/latestNews/addLatestNews.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },
  //latestNews

  //connectUs
  {
    path: '/connectUs',
    name: 'connectUs',
    component: () =>
      import('@/views/manager/aboutSchool/connectUs.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //paymentRequired
  {
    path: '/paymentRequired',
    name: 'paymentRequired',
    component: () =>
      import('@/views/manager/paymentRequired/paymentRequired.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' || JSON.parse(localStorage.getItem('results')).account_type === 'assistance')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //Admin

  //adminAddSchool
  {
    path: '/adminAddSchool',
    name: 'adminAddSchool',
    component: () =>
      import('@/views/admin/schools/adminAddSchool.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken') && JSON.parse(localStorage.getItem('results')).account_type === 'admin') {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },


  //edit School
  {
    path: '/editSchool/school_name/:school_name/school_id/:school_id',
    name: 'editSchool',
    component: () =>
      import('@/views/admin/schools/editSchool.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken') && JSON.parse(localStorage.getItem('results')).account_type === 'admin') {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //adminHome
  {
    path: '/adminHome',
    name: 'adminHome',
    component: () =>
      import('@/views/admin/adminHome.vue'),
    // beforeEnter(to, from, next) {
    //   if (localStorage.getItem('accessToken') && account_type === 'admin') {
    //     next()
    //   } else {
    //     next('/login')
    //   }
    // },
    meta: {
      layout: 'content',
    },
  },

  // adminSubject
  {
    path: '/adminSubject',
    name: 'adminSubject',
    component: () =>
      import('@/views/admin/subject/subject.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken') && JSON.parse(localStorage.getItem('results')).account_type === 'admin') {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //addSchool
  {
    path: '/addSchool',
    name: 'addSchool',
    component: () =>
      import('@/views/admin/schools/addSchool.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken') && JSON.parse(localStorage.getItem('results')).account_type === 'admin') {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //schoolProfile
  {
    path: '/schoolProfile/:name/:id',
    name: 'schoolProfile',
    component: () =>
      import('@/views/admin/schoolProfile.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken') && JSON.parse(localStorage.getItem('results')).account_type === 'admin') {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //AddSchoolAccount
  {
    path: '/AddSchoolAccount/:id',
    name: 'AddSchoolAccount',
    component: () =>
      import('@/views/admin/addSchoolAccount.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken') && JSON.parse(localStorage.getItem('results')).account_type === 'admin') {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //AddSchoolAccount
  {
    path: '/adminJoinUs',
    name: 'adminJoinUs',
    component: () =>
      import('@/views/admin/joinUs/joinUs.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken') && JSON.parse(localStorage.getItem('results')).account_type === 'admin') {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //adminContactUs
  {
    path: '/adminContactUs',
    name: 'adminContactUs',
    component: () =>
      import('@/views/admin/contactUs/contactUs.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken') && JSON.parse(localStorage.getItem('results')).account_type === 'admin') {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //accountHome
  {
    path: '/accountHome',
    name: 'accountHome',
    component: () =>
      import('@/views/account/accountHome/accountHome.vue'),
    // beforeEnter(to, from, next) {
    //     if (localStorage.getItem('accessToken') && JSON.parse(localStorage.getItem('results')).account_type === 'admin') {
    //         next()
    //     } else {
    //         next('/')
    //     }
    // },
    meta: {
      layout: 'content',
    },
  },

  //setting
  {
    path: '/setting',
    name: 'setting',
    component: () =>
      import('@/views/setting/setting.vue'),
    // beforeEnter(to, from, next) {
    //     if (localStorage.getItem('accessToken') && JSON.parse(localStorage.getItem('results')).account_type === 'admin') {
    //         next()
    //     } else {
    //         next('/')
    //     }
    // },
    meta: {
      layout: 'content',
    },
  },
  //login
  {
    path: '/',
    name: 'login',
    component: () =>
      import('@/views/Login.vue'),

    meta: {
      layout: 'blank',
      reload: true,
    },
  },
  // {
  //   path: '*',
  //   redirect: 'error-404',
  //   component: () =>
  //     import ('@/views/Error404.vue'),
  // },
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
