/* eslint-disable */

// const results = resultsLocalStorage.features_finger_id

let index = [{
  title: 'الصفحة الرئيسية',
  icon: 'fa-home',
  to: 'home',
},
// accountant
{
  title: 'الحسابات',
  icon: 'fa-file-contract',
  href: "https://counting.institute-iq.com",
},

//classSchool
{
  title: 'الصف والشعبة',
  icon: 'fa-school',
  to: 'classSchool',
},

// subjects
{
  title: 'المواد',
  icon: 'fa-book',
  to: 'subject',
},


//teachers
{
  title: 'الاساتذة',
  icon: 'fa-chalkboard-teacher',
  to: 'teachers',
},


//absenceTeacher
{
  title: 'حضور الاساتذة',
  icon: ' fa-clock',
  children: [{
    title: 'عرض',
    to: 'absenceShowTeacher',
  },
  {
    title: 'اضافة',
    to: 'absenceAddTeacher',
  },
  {
    title: 'اضافة محاضرة',
    to: 'lectureAddTeacher',
  },
  ],
},

//students
{
  title: 'الطلاب',
  icon: 'fa-user-graduate',
  children: [{
    title: 'الطلاب المسجلين',
    to: 'students',
    // alias: ['/studentInClass', '/addStudent', '/studentProfile'],
  },
  {
    title: 'جميع الطلاب',
    to: { name: 'allStudent' },
  },
  {
    title: 'الطلاب الموقوفين',
    to: { name: 'stopedStudent' },
  },
  {
    title: 'الطلاب المحذوفين',
    to: { name: 'deletedStudent' },
  },
  ],
},


//absence
{
  title: 'الحضور',
  icon: ' fa-clock',
  children: [{
    title: 'عرض',
    to: 'absenceShow',
  },
  {
    title: 'اضافة',
    to: 'absenceAdd',
  },
  ],
},

//Review
{
  title: 'التقييم',
  icon: 'fa fa-comments',
  children: [{
    title: 'عرض',
    to: 'showReview',
  },
  {
    title: 'اضافة',
    to: 'reviewAdd',
  },
  ],
},

//DailyExams
{
  title: 'الامتحانات اليومية',
  icon: 'fa fa-book-open',
  children: [{
    title: 'عرض',
    to: 'showDailyExam',
  },
  {
    title: 'اضافة',
    to: 'dailyExamAdd',
  },
  ],
},

//exams
{
  title: 'الامتحانات',
  icon: 'fa-school',
  children: [{
    title: 'عرض',
    to: 'examsShow',
  },
  {
    title: 'اضافة',
    to: 'examsMultiAdd',
  },
  ],
},

//degrees
{
  title: 'الدرجات',
  icon: 'fa fa-book-open',
  children: [{
    title: 'عرض',
    to: 'degreesShow',
  },
  {
    title: 'اضافة',
    to: 'degreesAdd',
  },
  ],
},

//schedule
{
  title: 'الجدول الدراسي',
  icon: 'fa-calendar-alt',
  to: 'schedule',
},


//courses
{
  title: 'الدورات',
  icon: 'fa-book-medical',
  to: 'courses',
},

//notification
{
  title: 'الاشعارات',
  icon: 'fa-bell',
  to: 'notification',
},

//latestNews
{
  title: 'اخر الاخبار',
  icon: 'fa-newspaper',
  to: 'latestNews',
},

//employee
{
  title: 'الموظفين',
  icon: 'fa-users',
  to: 'employees',
},

// drivers
{
  title: 'السواق',
  icon: 'fa-bus',
  to: 'drivers',
},

//stream
{
  title: 'البث المباشر',
  icon: 'fa-broadcast-tower',
  to: 'stream',
},


//connectUs
{
  title: 'اتصل بنا',
  icon: 'fa-phone-square',
  to: 'connectUs',
},
]

export default index
